import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ls-confirm-action-dialog',
  templateUrl: './confirm-action-dialog.component.html',
  styleUrls: ['./confirm-action-dialog.component.scss']
})
export class ConfirmActionDialogComponent implements OnInit {
  public confirmMessage: string;
  public confirmButtonText: string = 'Continue';
  public cancelButtonText: string = 'Cancel';

  constructor(
    private dialogRef: MatDialogRef<ConfirmActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.confirmMessage = this.data.message;
    this.confirmButtonText = this.data.confirmButtonText ?? this.confirmButtonText;
    this.cancelButtonText = this.data.cancelButtonText ?? this.cancelButtonText;
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
