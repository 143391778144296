export class RemitGridTotals {
  totalRemittances: number;
  totalNetAmount: string;
  totalAllocatedAmount: string;
  totalUnallocatedAmount: string;

  constructor(
    totalRemittances?: number,
    totalNetAmount?: number | string,
    totalAllocatedAmount?: number | string,
    totalUnallocatedAmount?: number | string
  ) {
    this.totalRemittances = totalRemittances ?? 0;
    this.totalNetAmount =
      typeof totalNetAmount === 'string' ? Number(totalNetAmount).toFixed(2) : totalNetAmount?.toFixed(2) ?? '0.00';
    this.totalAllocatedAmount =
      typeof totalAllocatedAmount === 'string'
        ? Number(totalAllocatedAmount).toFixed(2)
        : totalAllocatedAmount?.toFixed(2) ?? '0.00';
    this.totalUnallocatedAmount =
      typeof totalUnallocatedAmount === 'string'
        ? Number(totalUnallocatedAmount).toFixed(2)
        : totalUnallocatedAmount?.toFixed(2) ?? '0.00';
  }
}
