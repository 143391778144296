import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { CashAppInboundState } from './cash-app-inbound-reducer';

@Injectable()
export class CashAppInboundSelectors {
  appState = (state: any) => state.cashAppInbound;

  selectCashAppInbound = createSelector(this.appState, (state: CashAppInboundState) => ({
    cashAppInboundPages: state?.cashAppInboundPages == null ? null : copy(state.cashAppInboundPages)
  }));

  selectCashAppInboundComponentState = createSelector(this.appState, (state: CashAppInboundState) => ({
    cashAppInboundPages: copy(state?.cashAppInboundPages),
    cashAppInboundActiveTab: copy(state.cashAppInboundActiveTab),
    cashAppInboundActivePage: copy(state?.cashAppInboundActivePage),
    cashAppInboundTotals: copy(state?.cashAppInboundTotals)
  }));

  selectCashAppInboundDetailsComponentState = createSelector(this.appState, (state: CashAppInboundState) => ({
    cashAppInboundActive: copy(state?.cashAppInboundActive)
  }));
}
