import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from './Services';
import { Store } from '@ngrx/store';
import { AppActions } from './AppStateManagement';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { filter, takeUntil } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { LsUserRole } from './Models';
import { Router } from '@angular/router';

@Component({
  selector: 'ls-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private componentTeardown$: Subject<any> = new Subject<any>();
  constructor(
    public navigation: NavigationService,
    private store: Store,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) {
    this.navigation.startSaveHistory();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.componentTeardown$)
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length > 0 && !this.router.url.includes('loggedout')) {
          this.store.dispatch(AppActions.getConfig());

          const accounts = this.authService.instance.getAllAccounts();
          const lsAccount = accounts.find((acc) => {
            if (acc.idTokenClaims) {
              const userRoles = acc.idTokenClaims['extension_LsUserRoles'] as string;
              return userRoles.length > 0;
            }
            return false;
          });
          if (!lsAccount) {
            throw new Error('No Raistone account found.');
          }
          this.authService.instance.setActiveAccount(lsAccount);
          const activeAccount = this.authService.instance.getActiveAccount();
          const claims = activeAccount!.idTokenClaims;
          const rolesStr = claims!['extension_LsUserRoles'] as string;
          const userRole: LsUserRole = new LsUserRole(activeAccount!.localAccountId);
          userRole.roles = JSON.parse(rolesStr).map((role: any) => {
            let intUserId: number | null = Number.parseInt(role.UserId);
            if (!Number.isInteger(intUserId)) {
              intUserId = null;
            }
            return {
              userRole: role.UserRole,
              companyId: role.CompanyId,
              lsUserId: role.UserId
            };
          });
          this.store.dispatch(AppActions.setUserRole({ userRole: userRole }));
        }
      });
  }

  ngOnInit() {
    if (this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      const lsAccount = accounts.find((acc) => {
        if (acc.idTokenClaims) {
          const sso_token = acc.idTokenClaims['tfp'] as string;
          return sso_token.toLowerCase() === environment.policyName.toLowerCase();
        }
        return false;
      });
      if (!lsAccount) {
        throw new Error('No Raistone account found.');
      }
      this.authService.instance.setActiveAccount(lsAccount);
    }
  }

  ngOnDestroy() {
    this.componentTeardown$.next(null);
    this.componentTeardown$.complete();
  }
}
