import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UnauthedBaseComponent } from '../shared/unauthed-base.component';

@Component({
  selector: 'ls-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss']
})
export class LoggedOutComponent extends UnauthedBaseComponent {
  constructor(router: Router, route: ActivatedRoute, authService: MsalService, renderer: Renderer2) {
    super(router, route, authService, renderer);
  }

  // No preAuthentication needed for logged out flow
}
