import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ls-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent implements OnInit {
  public message: string;
  public okButtonText: string = 'OK';
  public title: string = 'Information';

  constructor(private dialogRef: MatDialogRef<InformationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.title = this.data.title;
    this.message = this.data.message;
    this.okButtonText = this.data.confirmButtonText ?? this.okButtonText;
  }

  ok() {
    this.dialogRef.close(true);
  }
}
