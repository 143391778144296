import { DateTime } from 'luxon';
import { DateUtils } from '../../Utils/DateUtils';
import { PaymentMethod } from './PaymentMethod';
export class FinancingOutbound {
  public compartmentName: string;
  public compartmentId: number;
  public currencyCode: string;
  public effectiveDate: DateTime;
  public facilityGroupName: string;
  public facilityGroupId: number;
  public paymentMethodId: string;
  public paymentMethodName: string;
  public paymentMethod: PaymentMethod;
  public proposedEffectiveDate: DateTime;
  public receiptDate: DateTime;
  public recipientId: number;
  public recipientName: string;
  public recipientRoleTypeName: string;
  public remittancePurpose: string;
  public remitStatusId: string;
  public remittanceAllocatedAmount: number;
  public remittanceNetAmount: number;
  public remittanceReferenceId: number;
  public remittanceUnallocatedAmount: number;
  public operationsOwner: string;
  public remitTypeId: string;
  public recipientBankAccountId: number;

  constructor(
    remittanceReferenceId?: number,
    remitStatusId?: string,
    compartmentName?: string,
    compartmentId?: number,
    currencyCode?: string,
    effectiveDate?: string | DateTime,
    facilityGroupName?: string,
    facilityGroupId?: number,
    paymentMethodId?: string,
    paymentMethodName?: string,
    proposedEffectiveDate?: string | DateTime,
    receiptDate?: string | DateTime,
    recipientId?: number,
    recipientName?: string,
    recipientRoleTypeName?: string,
    remittanceAllocatedAmount?: number,
    remittanceNetAmount?: number,
    remittancePurpose?: string,
    remittanceUnallocatedAmount?: number,
    operationOwner?: string,
    remitTypeId?: string,
    recipientBankAccountId?: number
  ) {
    this.compartmentName = compartmentName;
    this.compartmentId = compartmentId;
    this.currencyCode = currencyCode;
    this.effectiveDate = DateUtils.getDateTime(effectiveDate);
    this.facilityGroupName = facilityGroupName;
    this.facilityGroupId = facilityGroupId;
    this.paymentMethodId = paymentMethodId;
    this.paymentMethodName = paymentMethodName;
    this.paymentMethod = new PaymentMethod(paymentMethodId, paymentMethodName, true);
    this.proposedEffectiveDate = DateUtils.getDateTime(proposedEffectiveDate);
    this.receiptDate = DateUtils.getDateTime(receiptDate);
    this.recipientId = recipientId;
    this.recipientName = recipientName;
    this.recipientRoleTypeName = recipientRoleTypeName;
    this.remitStatusId = remitStatusId;
    this.remittanceAllocatedAmount = remittanceAllocatedAmount;
    this.remittanceNetAmount = remittanceNetAmount;
    this.remittancePurpose = remittancePurpose;
    this.remittanceReferenceId = remittanceReferenceId;
    this.remittanceUnallocatedAmount = remittanceUnallocatedAmount;
    this.operationsOwner = operationOwner;
    this.remitTypeId = remitTypeId;
    this.recipientBankAccountId = recipientBankAccountId;
  }
}
