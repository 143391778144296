import { DateTime } from 'luxon';

export class Pol {
  id: number | string;
  instrumentId: number | string;
  currency: string;
  netAmount: string;
  advanceAmount: string;
  remainderAmount: string;
  expectedDueDate: DateTime;
  pobId: number | string;
  polEligibilityStatus: string;
  ineligibleReason: string;
  polFinanceStatus: string;

  constructor(
    id: number | string,
    instrumentId: number | string,
    currency: string,
    netAmount: string,
    advanceAmount: string,
    remainderAmount: string,
    expectedDueDate: DateTime,
    polEligibilityStatus: string,
    polFinanceStatus: string,
    ineligibleReason?: string,
    pobId?: number | string
  ) {
    this.id = id;
    this.instrumentId = instrumentId ?? null;
    this.currency = currency ?? null;
    this.netAmount = netAmount ?? null;
    this.advanceAmount = advanceAmount ?? null;
    this.remainderAmount = remainderAmount;
    this.expectedDueDate = expectedDueDate ?? null;
    this.polEligibilityStatus = polEligibilityStatus ?? null;
    this.polFinanceStatus = polFinanceStatus ?? null;
    if (ineligibleReason != null) {
      this.ineligibleReason = ineligibleReason;
    }
    if (pobId != null) {
      this.pobId = pobId;
    }
  }
}
