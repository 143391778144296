import {
  ProductCurrency,
  FacilityGroupMethod,
  OperationsCenter,
  FacilitySeller,
  FacilityDebtor,
  FacilityList,
  ParticipantGroup
} from '.';
import { GenericLookup } from '@limestone/ls-shared-modules';
import { OperationsOwner } from './OperationsOwner';

export class FacilityGroup {
  id: number | string;
  participantGroup?: ParticipantGroup;
  productCurrency?: ProductCurrency;
  facilityGroupMethod?: FacilityGroupMethod;
  operationsCenter?: OperationsCenter;
  seller?: FacilitySeller;
  debtor?: FacilityDebtor;
  compartment?: GenericLookup<number>;
  facilityGroupFacilities?: FacilityList[];
  operationsOwner: OperationsOwner;

  constructor(
    id?: number | string,
    participantGroup?: ParticipantGroup,
    productCurrency?: ProductCurrency,
    facilityGroupMethod?: FacilityGroupMethod,
    operationsCenter?: OperationsCenter,
    seller?: FacilitySeller,
    debtor?: FacilityDebtor,
    compartment?: GenericLookup<any>,
    facilityGroupFacilities?: FacilityList[],
    operationsOwner?: OperationsOwner
  ) {
    this.id = id ?? null;
    this.participantGroup = participantGroup ?? null;
    this.productCurrency = productCurrency
      ? new ProductCurrency(
          productCurrency.id,
          productCurrency.productId,
          productCurrency.isActive,
          productCurrency.currency
        )
      : null;
    this.facilityGroupMethod = facilityGroupMethod
      ? new FacilityGroupMethod(facilityGroupMethod.id, facilityGroupMethod.name, facilityGroupMethod.isActive)
      : null;
    this.operationsCenter = operationsCenter
      ? new OperationsCenter(operationsCenter.id, operationsCenter.name, operationsCenter.isActive)
      : null;
    this.seller = seller ? new FacilitySeller(seller.id, seller.name, seller.isActive) : null;
    this.debtor = debtor ? new FacilityDebtor(debtor.id, debtor.name, debtor.isActive) : null;
    this.compartment = compartment ? new GenericLookup<number>(compartment.id, compartment.name) : null;

    this.facilityGroupFacilities = facilityGroupFacilities
      ? facilityGroupFacilities.map(
          (f) =>
            new FacilityList(
              f.id,
              f.productCurrencyCode,
              f.sellerName,
              f.debtorName,
              f.sourceChannelCompanyName,
              f.integrationTypeName,
              f.isAutoXcelEnabled,
              f.isRemittanceEmailSent,
              f.lienPositionName,
              f.payXcelTypeName,
              f.facilityGroupId,
              f.isFacilityApproved,
              f.isApprovedForFacilityGroup,
              f.isApprovedForParticipantGroup
            )
        )
      : new Array<FacilityList>();
    this.operationsOwner = operationsOwner ? new OperationsOwner(operationsOwner.id, operationsOwner.name) : null;
  }
}
