import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UnauthedBaseComponent } from '../shared/unauthed-base.component';

@Component({
  selector: 'ls-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent extends UnauthedBaseComponent {
  constructor(router: Router, route: ActivatedRoute, authService: MsalService, renderer: Renderer2) {
    super(router, route, authService, renderer);
  }

  protected async preAuthentication(): Promise<void> {
    localStorage.clear();
    sessionStorage.clear();

    await this.authService.instance.clearCache({
      account: this.authService.instance.getActiveAccount()
    });
  }
}
