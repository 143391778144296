import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmActionDialogComponent } from '../Components/confirm-action-dialog/confirm-action-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmActionDialogService {
  constructor(private dialog: MatDialog) {}

  openActionConfirmationDialog(title: string, message: string, confirmButtonText?: string, cancelButtonText?: string) {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
        data: {
          title: title,
          message: message,
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText
        },
        width: '300px'
      });
      dialogRef.afterClosed().subscribe((result) => {
        resolve(result);
      });
    });
  }
}
