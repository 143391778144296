import { RemitLineItem } from './RemitLineItem';
import { ParticipantOffer } from './ParticipantOffer';
import { DateTime } from 'luxon';
import { DateUtils } from '../../Utils/DateUtils';
import { PaymentMethod } from './PaymentMethod';
import { CashAppRemitLineItem } from './CashAppRemitLineItem';

export class CashAppInboundDetails {
  facilityGroupName: string;
  facilityGroupId: number;
  participantGroupId: number;
  originator: string;
  originatorId: number;
  role: string;
  proposedEffectiveDate: DateTime;
  effectiveDate: DateTime;
  compartment: string;
  compartmentId: number;
  remittancePurpose: string;
  recipientBankAccount: string;
  paymentMethod: string | PaymentMethod;
  remittanceReferenceId: number;
  remittanceNetAmount: string;
  currencyCode: string;
  remittanceAllocatedAmount: string;
  remittanceUnallocatedAmount: string;
  receiptDate: DateTime;
  remitStatusId: string;
  lineItems: CashAppRemitLineItem[];
  offers: ParticipantOffer[];
  submittedById: number | null;
  approvedById: number | null;

  constructor(
    facilityGroupName?: string,
    facilityGroupId?: number,
    participantGroupId?: number,
    originator?: string,
    originatorId?: number,
    role?: string,
    proposedEffectiveDate?: DateTime,
    effectiveDate?: DateTime,
    compartment?: string,
    compartmentId?: number,
    remittancePurpose?: string,
    recipientBankAccount?: string,
    paymentMethod?: string | PaymentMethod,
    remittanceReferenceId?: number,
    remittanceNetAmount?: string,
    currencyCode?: string,
    remittanceAllocatedAmount?: string,
    remittanceUnallocatedAmount?: string,
    receiptDate?: DateTime,
    remitStatusId?: string,
    lineItems?: CashAppRemitLineItem[],
    offers?: ParticipantOffer[],
    submittedById?: number | null,
    approvedById?: number | null
  ) {
    this.facilityGroupName = facilityGroupName;
    this.facilityGroupId = facilityGroupId;
    this.participantGroupId = participantGroupId;
    this.originator = originator;
    this.originatorId = originatorId;
    this.role = role;
    this.proposedEffectiveDate = DateUtils.getDateTime(proposedEffectiveDate);
    this.effectiveDate = DateUtils.getDateTime(effectiveDate);
    this.compartment = compartment;
    this.compartmentId = compartmentId;
    this.remittancePurpose = remittancePurpose;
    this.recipientBankAccount = recipientBankAccount;
    this.paymentMethod = paymentMethod;
    this.remittanceReferenceId = remittanceReferenceId;
    this.remittanceNetAmount = remittanceNetAmount;
    this.currencyCode = currencyCode;
    this.remittanceAllocatedAmount = remittanceAllocatedAmount;
    this.remittanceUnallocatedAmount = remittanceUnallocatedAmount;
    this.receiptDate = DateUtils.getDateTime(receiptDate);
    this.remitStatusId = remitStatusId;
    this.lineItems = lineItems;
    this.offers = offers;
    this.submittedById = submittedById ?? null;
    this.approvedById = approvedById ?? null;
  }
}
