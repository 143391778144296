<div class="unauthorized-container">
  <h1>Logged out</h1>
  <p>You have successfully logged out!</p>

  <button (click)="retryAuthentication()" class="retry-button" [disabled]="isAuthenticating">
    {{ isAuthenticating ? 'Logging in...' : 'Log in' }}
  </button>

  <div *ngIf="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>

  <p class="help-text">
    Click the button above to log back in.
  </p>
</div>
