import { LsRole } from '@limestone/ls-shared-modules';
import { GeneralUtils } from '../../Utils/GeneralUtils';

export class LsUserRole {
  userId: string;
  lsUserId: number | null = null;
  get roles(): Array<{ userRole: LsUserRoleRole; companyId: number }> {
    return this._roles;
  }
  set roles(roles: Array<{ userRole: LsUserRoleRole; companyId: number; lsUserId: number | null }>) {
    this._roles = roles.map((r) => {
      if (!GeneralUtils.exists(this.lsUserId)) {
        this.lsUserId = r.lsUserId;
      }
      return {
        userRole: new LsUserRoleRole(r.userRole.Id, r.userRole.Name, r.userRole.IsActive),
        companyId: r.companyId
      };
    });
  }
  private _roles = new Array<{ userRole: LsUserRoleRole; companyId: number }>();

  constructor(userId: string) {
    this.userId = userId;
  }
}

export class LsUserRoleRole {
  Id: LsRole;
  Name: string;
  IsActive: boolean;

  constructor(id: string, name: string, isActive: boolean) {
    this.Id = id as LsRole;
    this.Name = name;
    this.IsActive = isActive;
  }
}
