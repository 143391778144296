import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import {
  MatLegacyTable as MatTable,
  MatLegacyTableDataSource as MatTableDataSource
} from '@angular/material/legacy-table';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ActiveFilter } from './grid-filter-modal/grid-filter-modal.component';
import { ColumnSorterComponent } from './column-sorter/column-sorter.component';
import { BehaviorSubject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Group } from './Models/Group';
import { GridButton, GridColumn, LsDataGridConfig } from './Models/LsDataGridConfig';
import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { DropdownListEvent } from '../../../Models';
import copy from 'fast-copy';
import {
  DataGridGroupingService,
  DataGridPaginatorComponent,
  DataGridSelectionService,
  DataGridService,
  GridCellComponent
} from './';
import { CoreComponent } from '../../CoreComponent';
import { AutocompleteEvent } from './Models/AutoCompleteEvent';
import { ShowHiddenCharactersPipe, TimePipe } from '../../../../Utils/Pipes';
import { DataChangeEvent } from './Models/DataChangeEvent';
import { SelectChangeEvent } from './Models/SelectChangeEvent';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DropdownOption, GridSort, SortDirection } from '@limestone/ls-shared-modules';
import { LinkData } from './grid-cell/grid-cell.component';
import { OpenedChangeEvent } from './Models/OpenedChangeEvent';
import { ConfirmActionDialogService } from '../../../Services/confirm-action-dialog.service';

interface ColumnStatus {
  column: GridColumn;
  status: boolean;
}

@Component({
  selector: 'ls-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  providers: [CurrencyPipe, DatePipe, TimePipe, TitleCasePipe, ShowHiddenCharactersPipe]
})
export class DataGridComponent extends CoreComponent implements OnInit, AfterViewInit {
  // HTML Elements
  @ViewChild('paginatorComponent', { static: false }) paginator: DataGridPaginatorComponent;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  @ViewChild(ColumnSorterComponent) sorter: ColumnSorterComponent;
  @ViewChildren('gridCellComponent') gridCells: QueryList<GridCellComponent>;

  ngAfterViewInit() {
    this.ngZone.onMicrotaskEmpty.pipe(takeUntil(this.componentTeardown$)).subscribe(() => {
      if (this.table?._contentColumnDefs?.some((col) => col.sticky || col.stickyEnd)) {
        this.table.updateStickyColumnStyles();
      }
    });
  }

  // Class variables
  columns: string[];
  viewableColumns: string[];
  groupByColumns: string[] = [];
  dataSource: MatTableDataSource<any | Group> = new MatTableDataSource<any[]>();
  expandedElement: any | null;
  controls = [];
  activeFilters$: BehaviorSubject<Map<string, ActiveFilter[]>> = new BehaviorSubject<Map<string, ActiveFilter[]>>(null);
  activeSorts$: BehaviorSubject<GridSort[]> = new BehaviorSubject<GridSort[]>(null);
  currentSort: GridSort[] = [];
  editIndex: number;
  pageSizeOptions: number[] = [];
  showHideColumns: boolean = false;
  multiSelect: boolean = false;
  singleSelect: boolean = false;
  displayFooter: boolean = true;
  allowExpansion: boolean = false;
  allowEditing: boolean = false;
  allowSingleClickEditing: boolean = false;
  allowAdding: boolean = false;
  formAdding: boolean = false;
  formEditing: boolean = false;
  allowDeleting: boolean = false;
  allowDragColumns: boolean = false;
  allowDragRows: boolean = false;
  allowExporting: boolean = false;
  suppressRowSelect: boolean = false;
  allowTableEditing: boolean | (() => boolean) = false;
  confirmCancelEditing: boolean | (() => boolean) = false;
  confirmCancelTitleOverride: string;
  confirmCancelMessageOverride: string;
  confirmCancelConfirmButtonOverride: string;
  confirmCancelCancelButtonOverride: string;
  isTableEditing: boolean = false;
  defaultDisableEditButtonIf = () => false;
  disableEditButtonIf: () => boolean = this.defaultDisableEditButtonIf;
  totalDataLength: number = 0;
  gridColumns: GridColumn[] = [];
  gridButtons: GridButton[] = [];
  uniqueIdentifiers: string[];
  columnStatus: Array<ColumnStatus> = [];
  autoCompleteMap: Map<GridColumn, DropdownOption[]> = new Map<GridColumn, DropdownOption[]>();
  page$ = new BehaviorSubject<PageEvent>(null);
  private gridDataDetails: Map<number | string, { config: LsDataGridConfig; data: any }> = new Map<
    number | string,
    { config: LsDataGridConfig; data: any }
  >();
  private _gridData = new BehaviorSubject<any[]>([]);
  private _dropdownListEvent = new BehaviorSubject<DropdownListEvent>(null);
  private _changeTracker: GridColumn[] = [];

  @Input() set lsDataGridConfig(conf: LsDataGridConfig) {
    if (conf) {
      this.pageSizeOptions = conf.pageSizeOptions ?? [];
      this.showHideColumns = conf.showHideColumns;
      this.multiSelect = conf.multiSelect;
      this.singleSelect = conf.singleSelect;
      this.displayFooter = conf.displayFooter;
      this.allowExpansion = conf.allowExpansion;
      this.allowEditing = conf.allowEditing;
      this.allowSingleClickEditing = conf.allowSingleClickEditing;
      this.allowAdding = conf.allowAdding;
      this.allowDeleting = conf.allowDeleting;
      this.allowDragColumns = conf.allowDragColumns;
      this.allowDragRows = conf.allowDragRows;
      this.allowExporting = conf.allowExporting;
      this.gridColumns = copy(conf.gridColumns);
      this.gridButtons = conf.gridButtons ? [...conf.gridButtons] : [];
      this.uniqueIdentifiers = conf.uniqueIdentifier;
      this.formAdding = conf.formAdding;
      this.formEditing = conf.formEditing;
      this.suppressRowSelect = conf.suppressRowSelect;
      this.allowTableEditing = conf.tableEditConfig?.allowTableEditing ?? false;
      this.disableEditButtonIf = conf.tableEditConfig?.disableEditButtonIf ?? this.defaultDisableEditButtonIf;
      this.confirmCancelEditing = conf.tableEditConfig?.confirmCancelEditing ?? false;
      this.confirmCancelTitleOverride = conf.tableEditConfig?.confirmCancelOverrides?.titleOverride;
      this.confirmCancelMessageOverride = conf.tableEditConfig?.confirmCancelOverrides?.messageOverride;
      this.confirmCancelConfirmButtonOverride = conf.tableEditConfig?.confirmCancelOverrides?.confirmButtonOverride;
      this.confirmCancelCancelButtonOverride = conf.tableEditConfig?.confirmCancelOverrides?.cancelButtonOverride;
      this.columnStatus = conf.gridColumns.map((col) => {
        const obj: ColumnStatus = { column: col, status: true };
        return obj;
      });
      this.totalDataLength = conf.totalDataLength;
      this.gridDataDetails = conf.gridDataDetails;
      if (this.selectionService) {
        this.selectionService.clear();
      }
      this.page$.next(conf.page);
      this.setTable(conf);
      this.editRow(this.editIndex !== this.dataSource.data.length ? this.editIndex : null);
    }
  }

  @Input() allowFullTableEditing: boolean;

  // Value Inputs
  @Input() set gridData(value: any[]) {
    if (value) {
      this._gridData.next(value);
    }
  }
  get gridData() {
    return this._gridData.value;
  }

  @Input() set dropdownListEvent(ddlEventSubject: BehaviorSubject<DropdownListEvent>) {
    this._dropdownListEvent = ddlEventSubject;
  }

  @Input() set triggerTableEdit(trigger: boolean) {
    if (trigger) {
      this.beginTableEditing();
    }
  }

  // Event Emitters
  @Output() columnsReordered: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() pageSizeChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() sortOrderChange: EventEmitter<GridSort[]> = new EventEmitter<GridSort[]>();
  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterChange: EventEmitter<Map<string, ActiveFilter[]>> = new EventEmitter<Map<string, ActiveFilter[]>>();
  @Output() selectionChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() checkboxSelectionChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() add: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() formIsValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() autocompleteChange: EventEmitter<AutocompleteEvent> = new EventEmitter<AutocompleteEvent>();
  @Output() selectChange: EventEmitter<SelectChangeEvent> = new EventEmitter<SelectChangeEvent>();
  @Output() openedChange: EventEmitter<OpenedChangeEvent> = new EventEmitter<OpenedChangeEvent>();
  @Output() cellButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() export: EventEmitter<any> = new EventEmitter<any>();
  @Output() genericButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() editIndexChange: EventEmitter<number | null> = new EventEmitter<number | null>();
  @Output() addWithForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() editWithForm: EventEmitter<number | null> = new EventEmitter<number | null>();
  @Output() dragRowsResultEmitter: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() linkClicked: EventEmitter<LinkData> = new EventEmitter<LinkData>();
  @Output() beginTableEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitTableEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelTableEdit: EventEmitter<any> = new EventEmitter<any>();

  private readonly groupingService: DataGridGroupingService;
  private readonly selectionService: DataGridSelectionService;

  constructor(
    private currencyPipe: CurrencyPipe,
    public dataGridService: DataGridService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private confirmActionDialogService: ConfirmActionDialogService
  ) {
    super();
    this.groupingService = new DataGridGroupingService();
    this.selectionService = new DataGridSelectionService();
  }

  ngOnInit() {
    this._gridData
      .pipe(
        takeUntil(this.componentTeardown$),
        filter((data: any[]) => !!data)
      )
      .subscribe((data) => {
        this.dataSource.data = copy(data);
        this.table?.renderRows();
        if (this.editIndex !== null) {
          this.editRow(this.editIndex);
        }
      });

    this._dropdownListEvent
      .pipe(
        takeUntil(this.componentTeardown$),
        filter((ev) => ev !== undefined && ev !== null),
        map((ev) => {
          ev.columns.forEach((column) => {
            const gridCol = this.getGridColumn(column);
            switch (gridCol?.dataType) {
              case 'autocomplete':
                this.autoCompleteMap.set(gridCol, ev.options);
                break;
              case 'select':
                if (ev.gridRowIdProperty) {
                  var selectCell = this._getSelectCell(ev.gridRowIdProperty, ev.gridRowId, ev.columns[0]);
                  selectCell.dropdownOptions = ev.options;
                } else {
                  gridCol.dropdownOptions = ev.options;
                }
                break;
            }
          });
        })
      )
      .subscribe();
  }

  // ============ EVENT EMITTERS ===================== //

  /**
   * Emits when page options have changed.
   *
   * @param page Page event of page options after change.
   */
  public emitPageEvent(page: PageEvent) {
    if (this.multiSelect || this.singleSelect) {
      this.selectionService.clear();
    }
    this.editRow(null);
    this.pageSizeChange.emit(page);
  }

  /**
   * Emits when the column options have changed (order/hide/show)
   *
   * @param columns Array of column labels.
   */
  public emitColumnsChangedEvent(columns: string[]): void {
    let didChange = false;
    for (let i = 0; i < this.viewableColumns.length; i++) {
      if (this.viewableColumns[i] !== columns[i]) {
        didChange = true;
        break;
      }
    }
    if (didChange) {
      this.columnsReordered.emit(columns);
      this.setColumns(columns);
    }
  }

  /**
   * Emits when the sort has changed.
   *
   * @param sort Active column and direction of sort.
   */
  public emitSortChangedEvent(sort: GridSort): void {
    let active = this.gridColumns.find((c) => sort.active.includes(c.value)).value;
    let updateSortIndex = this.currentSort.findIndex((s) => s.active === active);
    if (updateSortIndex >= 0) {
      if (sort.direction === SortDirection.EMPTY) {
        this.currentSort = this.currentSort.filter((cs) => cs.active !== active);
      } else {
        let newSort: GridSort = {
          active: active,
          type: sort.type,
          direction: sort.direction
        };
        this.currentSort = [
          ...this.currentSort.slice(0, updateSortIndex),
          newSort,
          ...this.currentSort.slice(updateSortIndex + 1)
        ];
      }
    } else if (sort.direction !== SortDirection.EMPTY) {
      let newSort: GridSort = {
        active: active,
        type: sort.type,
        direction: sort.direction
      };
      this.currentSort = [...this.currentSort, newSort];
    }
    this.sortOrderChange.emit(this.currentSort);
    if (this.paginator) {
      this.paginator.paginator.firstPage();
    }
  }

  /**
   * Emits when the filter has changed.
   *
   * @param filters
   */
  public emitFilterChangedEvent(filters: Map<string, ActiveFilter[]>): void {
    this.filterChange.emit(filters);
  }

  /**
   * Emits when the data has changed.
   *
   * @param data Grid Data.
   * @param column Column of the cell whose value changed.
   * @param element The data element of the row that was edited.
   */
  public emitDataChangedEvent(data: DataChangeEvent, column: GridColumn, element: any): void {
    this._changeTracker.push(column);
    if (data.autoCompleteSelection) {
      this.dataGridService.setElementValueFromColumnValue(element, column.value, data.autoCompleteSelection);
      this._gridData.next(this.dataSource.data);
    } else {
      if (this._changeTracker.length === this.gridColumns.length) {
        this.dataChange.emit(this.dataSource.data);
        this._changeTracker = [];
      }
    }
  }
  // ================================================= //

  /**
   * Sets the table data, initializes the columns and sets unique identifier for each row.
   *
   * @param config LSDataGridConfig used to render table.
   * @private
   */
  private setTable(config: LsDataGridConfig): void {
    this.columns = config.gridColumns.map((col) => col.label);

    if (config.uniqueIdentifier) {
      if (config.totalDataLength > 0) {
        this.verifyUniqueIdentifier(this.dataSource.data[0], config.uniqueIdentifier);
      }
      this.dataSource.data?.forEach((d) => {
        this.setIdField(d);
      });
    }

    // Setting viewable columns.
    this.setColumns(this.columns);
    this.cdr.detectChanges();
  }

  /**
   * Ensures that the element has the supplied properties to build unique identifier.
   *
   * @param element Row element
   * @param uniqueIdentifier Array of properties to build unique identifier.
   * @private
   */
  private verifyUniqueIdentifier(element: any, uniqueIdentifier: string[]): void {
    uniqueIdentifier.forEach((idProp) => {
      const idPath = idProp.replace(this.dataGridService.objectDelimiter, '.');
      if (!this.hasProperty(element, idPath)) {
        throw new Error(`${typeof element} does not have property ${idProp}`);
      }
    });
  }

  /**
   * Checks if element has property.
   *
   * @param element Row element
   * @param prop Property to check if exists
   * @private
   */
  private hasProperty(element: any, prop: string): boolean {
    const propNames = prop.split('.');
    let elementValue = element;
    for (const propName of propNames) {
      if (!!elementValue) {
        if (!elementValue.hasOwnProperty(propName)) {
          return false;
        }
        elementValue = elementValue[propName];
      }
    }
    return true;
  }

  /**
   * Sets the unique id field of the element
   *
   * @param element Row element
   * @param isNew If new row was added, it appends _NEW to the unique id field.
   *
   * @private
   */
  private setIdField(element: any, isNew: boolean = false): void {
    const idFields = this.uniqueIdentifiers.map((idProp) =>
      this.dataGridService.getElementValueFromColumnValue(idProp, element)
    );
    element['id'] = idFields.join('_');
    if (isNew) {
      element['id'] += '_NEW';
    }
  }

  /**
   * Sets the columns for the data grid. Includes additional row for multiselect checkbox and view details button when
   * config options are set to true.
   *
   * @param columns Column labels for the data grid.
   * @private
   */
  private setColumns(columns: string[]): void {
    if (this.multiSelect) {
      this.viewableColumns = ['select', ...columns];
      this.selectionService.setSelectionMode('multi');
    } else if (this.singleSelect) {
      this.viewableColumns = [...columns];
      this.selectionService.setSelectionMode('single');
    } else {
      this.viewableColumns = [...columns];
    }
    if (this.allowExpansion || this.allowEditing || this.allowSingleClickEditing) {
      this.viewableColumns = [...this.viewableColumns, 'details'];
    }
  }

  /**
   * Gets the value for the footer cell.
   *
   * @param column Column for the footer cell.
   *
   * @return displayValue Value to be displayed in the cell.
   */
  public getFooter(column: GridColumn): string | number {
    if (column) {
      let displayVal = column.footerVal;
      if (column.currencyCode) {
        displayVal = this.currencyPipe.transform(displayVal, column.currencyCode);
      }
      return displayVal;
    }
  }

  /**
   * Returns the GridColumn object based on the display label.
   *
   * @param columnLabel Label of the column to fetch.
   */
  public getGridColumn(columnLabel: string): GridColumn {
    return this.gridColumns.find((c) => c.label === columnLabel);
  }

  // Edit
  /**
   * Sets the index of the row currently editing. Supply null when editing needs to stop.
   *
   * @param index Index of the row editing.
   */
  public editRow(index: number): void {
    this.editIndex = index;
    if (index !== null && index >= 0 && this.dataSource.data.length > 0) {
      this.formIsValid.emit(false);
    }
    this.editIndexChange.emit(index);
  }

  // Edit with Form
  public editRowWithForm(index: number): void {
    this.editWithForm.emit(index);
  }

  /**
   * Sets the status of the column.
   *
   * @param column Grid Column of the cell being editing.
   * @param valid Status of the cell.
   */
  public handleFormStatusChange(column: GridColumn, valid: boolean) {
    const columnStatus = this.columnStatus.find((col) => col.column.value === column.value);
    if (columnStatus) {
      columnStatus.status = valid;
    }
  }

  /**
   * Emits when an autocomplete field in the row has changed input value.
   *
   * @param element Data element of the row.
   * @param ev Autocomplete ev to be fired.
   */
  public handleAutoCompleteChange(element: any, ev: AutocompleteEvent) {
    ev.element = element;
    this.autocompleteChange.emit(ev);
  }

  /**
   * Emits The Selection When A User Makes A Select Box Selection
   * @param $event
   */
  public handleSelectChange($event: SelectChangeEvent) {
    this.selectChange.emit($event);
  }

  /**
   * Emits The Opened Change event When A User Opens A Select Box
   * @param $event
   */
  public handleOpenedChange($event: OpenedChangeEvent) {
    this.openedChange.emit($event);
  }

  /**
   * Emits when a grid cell button has been clicked.
   *
   * @param element The element of the row where the button cell was clicked.
   */
  public handleCellButtonClick(element: any): void {
    this.cellButtonClick.emit(element);
  }

  public handleLinkClick(linkData: LinkData): void {
    this.linkClicked.emit(linkData);
  }

  /**
   * Returns true when every cell in the row editing is valid.
   */
  public rowIsInValid(): boolean {
    return !this.columnStatus.every((c) => c.status);
  }

  /**
   * Signifies to the Grid Cell to finish editing and update values.
   */
  public applyEdit(): void {
    this.gridCells.filter((gridCell) => gridCell.isEditing).forEach((gridCell) => gridCell.submitEdit());
    this.editRow(null);
  }

  /**
   * Reverts row to last known state. Deletes row if row is new.
   */
  public revertData(element: any): void {
    if (typeof element.id === 'string') {
      const id: string = element.id;
      if (id.includes('NEW')) {
        this.dataChange.emit(this.dataSource.data.filter((d) => d.id !== element.id));
      }
    }
    this.editRow(null);
  }

  /**
   * Emits when the add button is clicked.
   */
  public addRow() {
    this.add.emit();
  }

  /**
   * Emits when the add button is clicked.
   */
  public addRowWithForm() {
    this.addWithForm.emit();
  }

  /**
   * Allow components to push new data to the grid.
   *
   * @param data Element to be added to the grid.
   */
  public push(data: any) {
    if (this.uniqueIdentifiers) {
      this.setIdField(data, true);
    }
    this.gridData.unshift(data);
    this.dataSource.data.unshift(data);
    this.table.renderRows();
    this.editRow(0);
  }

  /**
   * Emits when a row is selected for deletion.
   *
   * @param element Element to be deleted.
   */
  public submitDelete(element) {
    this.handleDelete.emit(element);
    this.editRow(null);
    this.delete(element);
    this.formIsValid.emit(true);
  }

  // Deletes the selected row
  /**
   * Deletes the row when multi-selection is enabled.
   *
   * @param element The element to be deleted.
   */
  public delete(element: any): void {
    // TODO: Remove logic from UI and emit event
    const index = this.dataSource.data.findIndex((d: any) => d.id === (element as { id: any }).id);
    if (index > -1) {
      this.dataSource.data.splice(index, 1);
    }
    // remove selection
    if (this.isSelected(this.dataSource.data[index])) {
      this.selectionService.select(this.dataSource.data[index]);
    }
    this.table.renderRows();
    this.totalDataLength = this.totalDataLength - 1;
  }

  /**
   * Handles multi-selection delete.
   */
  public deleteSelected(): void {
    this.selectionService.selected.forEach((el) => this.delete(el));
    this.selectionService.clear();
  }

  //Emits when export button is clicked

  public handleExport() {
    this.export.emit();
  }

  //Emits when generic button is clicked
  handleGenericButtonClick(event, eventName) {
    this.genericButtonClick.emit(eventName);
  }

  /**
   * When user tabs through a row, it will activate the next in edit mode.
   *
   * @param currentDataIndex Current index of row.
   */
  public toggleNext(currentDataIndex: number): void {
    let next = currentDataIndex + 1;
    if (this.paginator) {
      const currPage = this.paginator.currentPage.pageIndex;
      const pageSize = this.paginator.currentPage.pageSize;
      const totalLength = this.paginator.length;

      if (currPage > 0) {
        next = currPage * pageSize + next;
      }

      if (next >= totalLength) {
        this.applyEdit();
      } else {
        if (next >= (currPage + 1) * pageSize) {
          this.applyEdit();
          const paged = this.paginator.nextPage(next);
          if (paged) {
            this.editRow(0);
          }
        } else {
          this.applyEdit();
          this.editRow(next);
        }
      }
    } else {
      if (next <= this.dataSource.data.length) {
        this.applyEdit();
        this.editRow(next);
      } else {
        this.applyEdit();
      }
    }
  }

  /**
   * Calls toggle next. Used when row deletion not available.
   *
   * @param index index of the current row.
   */
  public toggleNextEdit(index: number) {
    if (!this.allowDeleting) {
      this.toggleNext(index);
    }
  }

  // ======= GROUPING HANDLER =========== //
  /**
   * Handles toggling into or out of grouping by column.
   *
   * @param column The column to be grouped by.
   */
  public handleGroupingClick(column: GridColumn): void {
    this.groupingService.groupToggle(this.groupByColumns, column, this.dataSource, this.paginator);
  }

  /**
   * Determines whether or not a row is a row.
   *
   * @param index Index of the row being checked.
   * @param element Element reference of the row being checked.
   */
  public isGroup(index: number, element: any): boolean {
    return element instanceof Group;
  }

  /**
   * Expands ro collapses the grouped row.
   *
   * @param row Row of grouping that will be expanded or collapsed.
   */
  public groupHeaderClick(row: any) {
    this.groupingService.groupHeaderClick(row, this.dataSource);
  }

  // =========== SELECTION HANDLER =========== //
  /**
   * Toggles selection or deselection of all data on the grid.
   */
  public masterToggle(event: any): void {
    this.selectionService.masterToggle(this.dataSource);
    if (event?.source.id.includes('checkbox')) {
      this.checkboxSelectionChange.emit(this.selectionService.selected);
    } else {
      this.selectionChange.emit(this.selectionService.selected);
    }
  }

  /**
   * Determines if all data in grid is selected.
   */
  public isAllSelected(): boolean {
    return this.selectionService.isAllSelected(this.dataSource);
  }

  /**
   * Determines if row i selected.
   *
   * @param row Row to be checked.
   */
  public isSelected(row: any): boolean {
    return this.selectionService.isSelected(row);
  }

  /**
   * Returns the number of selected rows.
   */
  public numberOfSelected(): number {
    return this.selectionService.selected.length;
  }

  /**
   * Returns whether of not any rows are selected.
   */
  public anySelected(): boolean {
    return this.selectionService.hasValue();
  }

  /**
   * Handles multi-select using shift button.
   *
   * @param event Click event of the row.
   * @param index Index of the row clicked.
   */
  public shiftSelect(event: MouseEvent, index: number): void {
    this.selectionService.shiftSelect(event, index, this.dataSource, this.paginator);
  }

  /**
   * Handles when a row is selected.
   *
   * @param row Row to be selected or deselected.
   */
  public select(row: any, event?: any): void {
    if (this.singleSelect || this.multiSelect) {
      this.selectionService.select(row);
      if (event?.source.id.includes('checkbox')) {
        this.checkboxSelectionChange.emit(this.selectionService.selected);
      } else {
        this.selectionChange.emit(this.selectionService.selected);
      }
    }
  }

  /**
   * Clears the current selections.
   */
  public clearSelections(): void {
    this.selectionService.clear();
    this.selectionChange.emit(this.selectionService.selected);
  }

  /**
   * Clears the active filters within the grid.
   */
  public clearFilters() {
    this.filterChange.next(new Map<string, ActiveFilter[]>());
  }

  // ======== ROW EXPANSION ========== //
  /**
   * Expands the selected row to show details view.
   *
   * @param event MouseEvent
   * @param row The element to be expanded.
   */
  public expandRow(event: MouseEvent, row: any): void {
    event.stopPropagation();
    this.expandedElement = this.expandedElement === row ? null : row;
  }

  /**
   * Used to determine that the row is not a grouping.
   *
   * @param index Index of the row.
   * @param item The element of the row.
   */
  public checkRow(index, item): boolean {
    return !(item instanceof Group);
  }

  /**
   * Checks if the row has details view.
   *
   * @param element
   */
  public hasDetails(element: any): boolean {
    return this.gridDataDetails && this.allowExpansion ? this.gridDataDetails.has(element.id) : false;
  }

  /**
   * Gets the grid config for the child view to be rendered.
   *
   * @param element The element whose details need to be rendered.
   */
  public getChildConfig(element: any): LsDataGridConfig {
    if (!element.hasOwnProperty('id')) {
      console.error(`Unable to get id for ${JSON.stringify(element)}. Details will not be shown.`);
    }
    return this.gridDataDetails.get(element.id).config;
  }

  /**
   * Gets the grid data for the child view to be rendered.
   *
   * @param element The element whose details need to be rendered.
   */
  public getChildData(element: any): any {
    if (!element.hasOwnProperty('id')) {
      console.error(`Unable to get id for ${JSON.stringify(element)}. Details will not be shown.`);
    }
    return this.gridDataDetails.get(element.id).data;
  }

  public getChildKeys(element: any) {
    if (!element.hasOwnProperty('id')) {
      console.error(`Unable to get id for ${JSON.stringify(element)}. Details will not be shown.`);
    }
    return Object.keys(this.gridDataDetails.get(element.id).data);
  }

  isGridBeingEdited(): boolean {
    return this.editIndex !== null && typeof this.editIndex === 'number' && this.editIndex >= 0;
  }

  onListDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
    this.table.renderRows();
    if (!!this.dragRowsResultEmitter) {
      this.dragRowsResultEmitter.emit(this.dataSource.data);
    }
  }

  updateActiveFilter(filterMap: Map<string, ActiveFilter[]>) {
    this.activeFilters$.next(filterMap);
  }

  public getAllTableData(): any[] {
    return [...new Set(this.gridCells.map((cell) => cell.element))];
  }

  private _getSelectCell(idProperty: string, id: number, columnName: string): GridCellComponent {
    var cell = this.gridCells.find(
      (cell) => cell.element[idProperty] === id && cell.column.label === columnName && cell.column.dataType === 'select'
    );
    return cell;
  }

  beginTableEditing(): void {
    this.isTableEditing = true;
    this.beginTableEdit.emit();
  }

  submitTableEditing(): void {
    this.isTableEditing = false;
    this.submitTableEdit.emit();
  }

  cancelTableEditing(): void {
    this.isTableEditing = false;
    this.cancelTableEdit.emit();
  }

  async toggleIsEditingCancel() {
    const canCancel =
      !this.confirmCancelEditingInternal() ||
      (await this.confirmActionDialogService.openActionConfirmationDialog(
        this.confirmCancelTitleOverride ?? 'Confirm Cancellation',
        this.confirmCancelMessageOverride ??
          'You have unsaved changes. If you cancel now, your edits will be lost. Are you sure you want to cancel?',
        this.confirmCancelConfirmButtonOverride ?? 'Confirm',
        this.confirmCancelCancelButtonOverride ?? 'Cancel'
      ));
    if (canCancel) {
      this.cancelTableEditing();
    }
  }

  allowTableEditingInternal() {
    return typeof this.allowTableEditing === 'function' ? this.allowTableEditing() : this.allowTableEditing;
  }

  confirmCancelEditingInternal() {
    return typeof this.confirmCancelEditing === 'function' ? this.confirmCancelEditing() : this.confirmCancelEditing;
  }
}
