import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { FinancingOutboundState } from './financing-outbound-reducer';

@Injectable()
export class FinancingOutboundSelectors {
  appState = (state: any) => state.financingOutbound;

  selectFinancingOutbound = createSelector(this.appState, (state: FinancingOutboundState) => ({
    financingOutboundPages: state?.financingOutboundPages == null ? null : copy(state.financingOutboundPages)
  }));

  selectFinancingOutboundComponentState = createSelector(this.appState, (state: FinancingOutboundState) => ({
    financingOutboundPages: copy(state?.financingOutboundPages),
    financingOutboundActivePage: copy(state?.financingOutboundActivePage),
    RemitGridTotals: copy(state?.RemitGridTotals)
  }));

  selectFinancingOutboundDetailsComponentState = createSelector(this.appState, (state: FinancingOutboundState) => ({
    financingOutboundActive: copy(state?.financingOutboundActive)
  }));
}
