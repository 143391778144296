import { createSelector } from '@ngrx/store';
import { AppState } from './app-reducer';
import { Injectable } from '@angular/core';

@Injectable()
export class AppSelectors {
  appState = (state: any) => state.app;
  selectUserRole = createSelector(this.appState, (state: AppState) => state.userRole);
  selectUser = createSelector(this.appState, (state: AppState) => state.user);
  selectConfig = createSelector(this.appState, (state: AppState) => state.config);
}
