import { DateTime } from 'luxon';

export class BlackoutDate {
  calendarId: number;
  date: DateTime;
  reason?: string;
  constructor(calendarId?: number, date?: DateTime, reason?: string) {
    this.calendarId = calendarId;
    this.date = date ?? null;
    this.reason = reason ?? null;
  }
}
