import { createAction, props } from '@ngrx/store';
import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import {
  ActivePage,
  ExportRequest,
  InboundRemitGrid,
  RemitGridTotals,
  FundingStatus,
  Page,
  PageRequest,
  FinancingInboundDetails
} from '../../../../Models';

export abstract class FinancingInboundActions extends LsAction {
  /* eslint-disable */
  static FINANCING_INBOUND: Readonly<string> = 'FINANCING_INBOUND';
  static FINANCING_INBOUND_STATUS: Readonly<string> = 'FINANCING_INBOUND_STATUS';
  static FINANCING_INBOUNDS: Readonly<string> = 'FINANCING_INBOUNDS';
  static FINANCING_INBOUNDS_STATUS: Readonly<string> = 'FINANCING_INBOUNDS_STATUS';
  static FINANCING_INBOUND_STATUSES: Readonly<string> = 'FINANCING_INBOUND_STATUSES';
  static FINANCING_INBOUND_EXPORT: Readonly<string> = 'EXPORT/FINANCING_INBOUND';
  static FINANCING_INBOUND_ACTIVE_PAGE: Readonly<string> = 'FINANCING_INBOUND_ACTIVE_PAGE';
  static FINANCING_INBOUND_DETAILS: Readonly<string> = 'FINANCING_INBOUND_DETAILS';
  static FINANCING_INBOUND_LINE_ITEMS_EXPORT: Readonly<string> = 'EXPORT/FINANCING_INBOUND/LINE_ITEMS';
  /* eslint-enable */

  static getFinancingInbounds = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUNDS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setFinancingInbounds = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUNDS, ActionType.SET),
    props<{ page: Page<InboundRemitGrid>; totals: RemitGridTotals }>()
  );

  static getFinancingInbound = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND, ActionType.GET),
    props<{ id: number }>()
  );

  static setFinancingInbound = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND, ActionType.SET),
    props<{ financingInbound: InboundRemitGrid }>()
  );

  static clearFinancingInbounds = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND, ActionType.CLEAR)
  );

  static updateFinancingInboundStatus = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND_STATUS, ActionType.UPDATE),
    props<{ financingInbound: InboundRemitGrid; newStatus: string }>()
  );

  static financingInboundSuccessful = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND, ActionType.COMPLETE)
  );

  static financingInboundUnsuccessful = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static updateFinancingInboundsStatus = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUNDS_STATUS, ActionType.UPDATE),
    props<{ financingInbounds: InboundRemitGrid[]; newStatus: string; pageRequest: PageRequest }>()
  );

  static updateFinancingInbounds = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUNDS, ActionType.UPDATE),
    props<{ financingInbounds: InboundRemitGrid[]; pageRequest: PageRequest }>()
  );

  static updateFinancingInboundsSuccessful = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUNDS, ActionType.COMPLETE)
  );

  static updateFinancingInboundsUnsuccessful = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUNDS, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getFinancingInboundStatuses = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND_STATUSES, ActionType.GET)
  );

  static setFinancingInboundStatuses = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND_STATUSES, ActionType.SET),
    props<{ statuses: FundingStatus[] }>()
  );

  static triggerFinancingInboundExport = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND_EXPORT, ActionType.TRIGGER),
    props<{ exportRequest: ExportRequest }>()
  );

  static financingInboundExportSuccessful = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND_EXPORT, ActionType.COMPLETE)
  );

  static financingInboundExportUnsuccessful = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getFinancingInboundActivePage = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND_ACTIVE_PAGE, ActionType.GET)
  );

  static setFinancingInboundActivePage = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static getFinancingInboundDetails = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND_DETAILS, ActionType.GET),
    props<{ id: number }>()
  );

  static setFinancingInboundDetails = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND_DETAILS, ActionType.SET),
    props<{ financingInbound: FinancingInboundDetails }>()
  );

  static updateFinancingInboundDetails = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND_DETAILS, ActionType.UPDATE),
    props<{ financingInbound: FinancingInboundDetails; newStatus: string }>()
  );

  static financingInboundDetailsSaveSuccessful = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND_DETAILS, ActionType.COMPLETE)
  );

  static financingInboundDetailsSaveUnsuccessful = createAction(
    FinancingInboundActions.getActionName(FinancingInboundActions.FINANCING_INBOUND_DETAILS, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static financingInboundLineItemsExport = createAction(
    FinancingInboundActions.getActionName(
      FinancingInboundActions.FINANCING_INBOUND_LINE_ITEMS_EXPORT,
      ActionType.TRIGGER
    ),
    props<{ id: number; exportRequest: ExportRequest }>()
  );

  static financingInboundLineItemsExportSuccessful = createAction(
    FinancingInboundActions.getActionName(
      FinancingInboundActions.FINANCING_INBOUND_LINE_ITEMS_EXPORT,
      ActionType.COMPLETE
    )
  );

  static financingInboundLineItemsExportUnsuccessful = createAction(
    FinancingInboundActions.getActionName(
      FinancingInboundActions.FINANCING_INBOUND_LINE_ITEMS_EXPORT,
      ActionType.ERROR
    ),
    props<{ errors: string[] }>()
  );
}
