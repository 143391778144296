import { ActionType, LsAction, PageRequest } from '@limestone/ls-shared-modules';
import { createAction, props } from '@ngrx/store';
import {
  ActivePage,
  ExportRequest,
  FinancingOutbound,
  FinancingOutboundDetails,
  RemitGridTotals,
  FundingStatus,
  Page
} from 'src/app/Models';

export abstract class FinancingOutboundActions extends LsAction {
  /* eslint-disable */
    static FINANCING_OUTBOUND: Readonly<string> = 'FINANCING_OUTBOUND';
    static FINANCING_OUTBOUNDS: Readonly<string> = 'FINANCING_OUTBOUNDS';
    static FINANCING_OUTBOUNDS_STATUS: Readonly<string> = 'FINANCING_OUTBOUNDS_STATUS';
    static FINANCING_OUTBOUND_EXPORT: Readonly<string> = 'EXPORT/FINANCING_OUTBOUND';
    static FINANCING_OUTBOUND_ACTIVE_PAGE: Readonly<string> = 'FINANCING_OUTBOUND_ACTIVE_PAGE';
    static FINANCING_OUTBOUND_DETAILS: Readonly<string> = 'FINANCING_OUTBOUND_DETAILS';
    /* eslint-enable */
  static FINANCING_OUTBOUND_LINE_ITEMS_EXPORT: Readonly<string> = 'FINANCING_OUTBOUND_LINE_ITEMS_EXPORT';

  static getFinancingOutbounds = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUNDS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setFinancingOutbounds = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUNDS, ActionType.SET),
    props<{ page: Page<FinancingOutbound>; totals: RemitGridTotals }>()
  );

  static getFinancingOutbound = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND, ActionType.GET),
    props<{ id: number }>()
  );

  static setFinancingOutbound = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND, ActionType.SET),
    props<{ financingOutbound: FinancingOutbound }>()
  );

  static financingOutboundSuccessful = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND, ActionType.COMPLETE)
  );

  static financingOutboundUnsuccessful = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static clearFinancingOutbounds = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND, ActionType.CLEAR)
  );

  static updateFinancingOutbound = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND, ActionType.UPDATE),
    props<{ financingOutbound: FinancingOutbound; newStatus: string }>()
  );

  static updateFinancingOutbounds = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUNDS, ActionType.UPDATE),
    props<{ financingOutbounds: FinancingOutbound[]; pageRequest: PageRequest }>()
  );

  static updateFinancingOutboundsStatus = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUNDS_STATUS, ActionType.UPDATE),
    props<{ financingOutbounds: FinancingOutbound[]; newStatus: string; pageRequest: PageRequest }>()
  );

  static getFinancingOutboundStatuses = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUNDS_STATUS, ActionType.GET)
  );

  static setFinancingOutboundStatuses = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUNDS_STATUS, ActionType.SET),
    props<{ statuses: FundingStatus[] }>()
  );

  static triggerFinancingOutboundExport = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND_EXPORT, ActionType.TRIGGER),
    props<{ exportRequest: ExportRequest }>()
  );

  static financingOutboundExportSuccessful = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND_EXPORT, ActionType.COMPLETE)
  );

  static financingOutboundExportUnsuccessful = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getFinancingOutboundActivePage = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND_ACTIVE_PAGE, ActionType.GET)
  );

  static setFinancingOutboundActivePage = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static getFinancingOutboundDetails = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND_DETAILS, ActionType.GET),
    props<{ id: number }>()
  );

  static setFinancingOutboundDetails = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND_DETAILS, ActionType.SET),
    props<{ financingOutbound: FinancingOutboundDetails }>()
  );

  static updateFinancingOutboundDetails = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND_DETAILS, ActionType.UPDATE),
    props<{ financingOutbound: FinancingOutboundDetails; newStatus: string }>()
  );

  static financingOutboundDetailsSaveSuccessful = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND_DETAILS, ActionType.COMPLETE)
  );

  static financingOutboundDetailsSaveUnsuccessful = createAction(
    FinancingOutboundActions.getActionName(FinancingOutboundActions.FINANCING_OUTBOUND_DETAILS, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static financingOutboundLineItemsExport = createAction(
    FinancingOutboundActions.getActionName(
      FinancingOutboundActions.FINANCING_OUTBOUND_LINE_ITEMS_EXPORT,
      ActionType.TRIGGER
    ),
    props<{ id: number; exportRequest: ExportRequest }>()
  );

  static financingOutboundLineItemsExportSuccessful = createAction(
    FinancingOutboundActions.getActionName(
      FinancingOutboundActions.FINANCING_OUTBOUND_LINE_ITEMS_EXPORT,
      ActionType.COMPLETE
    )
  );

  static financingOutboundLineItemsExportUnsuccessful = createAction(
    FinancingOutboundActions.getActionName(
      FinancingOutboundActions.FINANCING_OUTBOUND_LINE_ITEMS_EXPORT,
      ActionType.ERROR
    ),
    props<{ errors: string[] }>()
  );
}
