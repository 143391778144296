import { Component, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { AppSelectors } from 'src/app/AppStateManagement/app-selectors';
import { Store, select } from '@ngrx/store';
import { User, NavData, FeatureFlag } from '../../Models';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MsalService } from '@azure/msal-angular';
import { CoreComponent } from '../CoreComponent';
import { SignalRService } from '../../Services/signalr.service';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
  LegacyTextOnlySnackBar as TextOnlySnackBar
} from '@angular/material/legacy-snack-bar';
import { FeatureFlagActions, FeatureFlagSelectors } from '@limestone/ls-shared-modules';
import { StoreUpdateService } from '../../Modules/Ops-Module/Services/store-update.service';

@Component({
  selector: 'ls-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent extends CoreComponent implements OnInit {
  public title = 'Limestone';
  public username = new BehaviorSubject('');
  public featureFlags = new BehaviorSubject([]);
  public routes: Map<string, NavData[]> = new Map();
  public navExpanded = true;

  private activeBaseRoute = '';
  private _currentSnackBar: MatSnackBarRef<TextOnlySnackBar>;

  constructor(
    private store: Store,
    private authService: MsalService,
    public snackBar: MatSnackBar,
    public signalRService: SignalRService,
    public featureFlagSelectors: FeatureFlagSelectors,
    private appSelectors: AppSelectors,
    private storeUpdateService: StoreUpdateService
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this.router.events.pipe(
        filter((routerEvent) => routerEvent instanceof NavigationEnd),
        // This is needed for a reload or loading directly to a child route (/ops/ingest). The router event is not picked up so
        // we need to initialize with one.
        startWith({
          urlAfterRedirects: this.router.url,
          url: this.router.url,
          id: 0
        })
      ),
      this.store.select(this.featureFlagSelectors.selectFeatureFlags)
    ])
      .pipe(
        takeUntil(this.componentTeardown$),
        map(([routerEvent, featureFlags]) => {
          const navEnd = routerEvent as NavigationEnd;
          if (featureFlags) {
            this.featureFlags.next(featureFlags);
          }

          if (featureFlags) {
            this.activeBaseRoute = navEnd.urlAfterRedirects.split('/')[1];
            switch (this.activeBaseRoute) {
              case 'ops':
                this.routes = this.filterOutInaccessibleRoutes(environment.opsRouteMap, featureFlags);
                break;
              case 'participant':
                break;
              case 'supplier':
                break;
              default:
                break;
            }
          }
          [...this.routes.values()].forEach((routes) => {
            routes.forEach((route) => (route.isActive = navEnd.urlAfterRedirects.includes(route.route)));
          });
        })
      )
      .subscribe();

    this.store
      .pipe(
        takeUntil(this.componentTeardown$),
        select(this.appSelectors.selectUser),
        filter((user) => user !== null && user !== undefined),
        map((user: User) => this.username.next(user.fullName))
      )
      .subscribe();

    if (this.signalRService) {
      this.signalRService.notifications.subscribe((message) => {
        if (this._currentSnackBar) {
          this._currentSnackBar.dismiss();
        }
        this._currentSnackBar = this.snackBar.open(message, 'X', {
          duration: 5000,
          panelClass: ['successful-sb'],
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
      });
      this.signalRService.appConfigurationChanges.subscribe((message) => {
        this.store.dispatch(FeatureFlagActions.getFeatureFlags());
      });
      this.signalRService.storeUpdates.subscribe((storeUpdates) => {
        this.storeUpdateService.processStoreUpdate(storeUpdates[0]);
      });
    }
  }

  filterOutInaccessibleRoutes(routeMap: Map<string, NavData[]>, featureFlags: FeatureFlag[]): Map<string, NavData[]> {
    const filteredRouteMap: Map<string, NavData[]> = new Map<string, NavData[]>();
    for (const key of routeMap.keys()) {
      const section = routeMap.get(key);
      section.forEach((route: NavData) => {
        if (
          typeof route.featureFlagId === 'undefined' ||
          !featureFlags ||
          featureFlags.some((flag) => flag.enabled && flag.id === route.featureFlagId)
        ) {
          const navDataArray = filteredRouteMap.get(key) || [];
          navDataArray.push(route);
          filteredRouteMap.set(key, navDataArray);
        }
      });
    }
    return filteredRouteMap;
  }

  logoff() {
    this.authService.logoutRedirect({
      account: this.authService.instance.getActiveAccount(),
      postLogoutRedirectUri: '/loggedout',
      idTokenHint: this.authService.instance.getActiveAccount()?.idToken
    });
  }

  navTo(route: NavData) {
    this.navExpanded = false;
    if (route.route.includes('/')) {
      const routePathArray = route.route.split('/');
      this.router.navigate([this.activeBaseRoute, ...routePathArray]);
    } else {
      this.router.navigate([this.activeBaseRoute, route.route]);
    }
  }

  getKeys(): string[] {
    return Array.from(this.routes.keys());
  }
}
