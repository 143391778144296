import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { PolPriceState } from './pol-price-reducer';

@Injectable()
export class PolPriceSelectors {
  appState = (state: any) => state.polPrice;

  selectPolPriceStatuses = createSelector(this.appState, (state: PolPriceState) => copy(state.polPriceStatuses));
  selectPolPriceTypes = createSelector(this.appState, (state: PolPriceState) => copy(state.polPriceTypes));

  selectPolPriceComponentState = createSelector(this.appState, (state: PolPriceState) => ({
    polPricePages: state?.polPricePages,
    polPriceActivePage: state?.polPriceActivePage
  }));
}
