<div class="unauthorized-container">
  <h1>Unauthorized Access</h1>
  <p>You don't have permission to view this page, or your session has expired.</p>

  <button (click)="retryAuthentication()" class="retry-button" [disabled]="isAuthenticating">
    {{ isAuthenticating ? 'Reauthenticating...' : 'Reauthenticate' }}
  </button>

  <div *ngIf="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>

  <p class="help-text">
    Click the button above to attempt to reauthenticate, reauthorize, and return to your previous page.
  </p>
</div>
