import { CashAppOutbound, RemitGridTotals } from '.';
import { Page } from './Page';

export class CashAppOutboundData {
  page: Page<CashAppOutbound>;
  totals: RemitGridTotals;

  constructor(page?: Page<any>, totals?: RemitGridTotals) {
    this.page = page ?? null;
    this.totals = totals ?? null;
  }
}
