import { createAction, props } from '@ngrx/store';
import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import {
  ActivePage,
  ExportRequest,
  InboundRemitGrid,
  RemitGridTotals,
  Page,
  PageRequest,
  CashAppInboundDetails
} from '../../../../Models';

export abstract class CashAppInboundActions extends LsAction {
  /* eslint-disable */
  static CASH_APP_INBOUND: Readonly<string> = 'CASH_APP_INBOUND';
  static CASH_APP_INBOUNDS: Readonly<string> = 'CASH_APP_INBOUNDS';
  static CASH_APP_INBOUND_STATUS: Readonly<string> = 'CASH_APP_INBOUND_STATUS';
  static CASH_APP_INBOUNDS_STATUS: Readonly<string> = 'CASH_APP_INBOUNDS_STATUS';
  static CASH_APP_INBOUND_EXPORT: Readonly<string> = 'EXPORT/CASH_APP_INBOUND';
  static CASH_APP_INBOUND_ACTIVE_PAGE: Readonly<string> = 'CASH_APP_INBOUND_ACTIVE_PAGE';
  static CASH_APP_INBOUND_DETAILS: Readonly<string> = 'CASH_APP_INBOUND_DETAILS';
  static CASH_APP_INBOUND_LINE_ITEMS_EXPORT: Readonly<string> = 'EXPORT/CASH_APP_INBOUND/LINE_ITEMS';
  /* eslint-enable */

  static getCashAppInbounds = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUNDS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setCashAppInbounds = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUNDS, ActionType.SET),
    props<{ page: Page<InboundRemitGrid>; totals: RemitGridTotals }>()
  );

  static getCashAppInbound = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND, ActionType.GET),
    props<{ id: number }>()
  );

  static cashAppInboundSuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND, ActionType.COMPLETE)
  );

  static cashAppInboundUnsuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static clearCashAppInbounds = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND, ActionType.CLEAR)
  );

  static updateCashAppInbound = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND, ActionType.UPDATE),
    props<{ cashAppInbound: InboundRemitGrid; newStatus: string }>()
  );

  static updateCashAppInboundStatus = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_STATUS, ActionType.UPDATE),
    props<{ cashAppInbound: InboundRemitGrid; newStatus: string }>()
  );

  static updateCashAppInbounds = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUNDS, ActionType.UPDATE),
    props<{ cashAppInbounds: InboundRemitGrid[]; pageRequest: PageRequest }>()
  );

  static updateCashAppInboundsStatus = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUNDS_STATUS, ActionType.UPDATE),
    props<{ cashAppInbounds: InboundRemitGrid[]; newStatus: string; pageRequest: PageRequest }>()
  );

  static triggerCashAppInboundExport = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_EXPORT, ActionType.TRIGGER),
    props<{ exportRequest: ExportRequest }>()
  );

  static cashAppInboundExportSuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_EXPORT, ActionType.COMPLETE)
  );

  static cashAppInboundExportUnsuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static setCashAppInboundActivePage = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static getCashAppInboundDetails = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_DETAILS, ActionType.GET),
    props<{ id: number }>()
  );

  static setCashAppInboundDetails = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_DETAILS, ActionType.SET),
    props<{ cashAppInbound: CashAppInboundDetails }>()
  );

  static updateCashAppInboundDetails = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_DETAILS, ActionType.UPDATE),
    props<{ cashAppInbound: CashAppInboundDetails; newStatus: string }>()
  );

  static cashAppInboundDetailsSaveSuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_DETAILS, ActionType.COMPLETE)
  );

  static cashAppInboundDetailsSaveUnsuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_DETAILS, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static cashAppInboundLineItemsExport = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_LINE_ITEMS_EXPORT, ActionType.TRIGGER),
    props<{ id: number; exportRequest: ExportRequest }>()
  );

  static cashAppInboundLineItemsExportSuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_LINE_ITEMS_EXPORT, ActionType.COMPLETE)
  );

  static cashAppInboundLineItemsExportUnsuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_LINE_ITEMS_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );
}
