import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { ActivePage, FacilityGroup, FacilityGroupList, Page, PageRequest } from '../../../../Models';

export abstract class FacilityGroupActions extends LsAction {
  static FACILITY_GROUPS = 'FACILITY_GROUPS';
  static FACILITY_GROUP = 'FACILITY_GROUP';
  static FACILITY_GROUP_ACTIVE_PAGE = 'FACILITY_GROUP_ACTIVE_PAGE';
  static FACILITY_GROUP_FACILITIES = 'FACILITY_GROUP_FACILITIES';
  static FACILITY_GROUP_FACILITIES_ACTIVE_PAGE = 'FACILITY_GROUP_FACILITIES_ACTIVE_PAGE';
  static ACTIVE_FACILITY_GROUP = 'ACTIVE_FACILITY_GROUP';
  static FACILITY_GROUP_FACILITIES_FILTER = 'FACILITY_GROUP_FACILITIES_FILTER';

  static getFacilityGroups = createAction(
    FacilityGroupActions.getActionName(FacilityGroupActions.FACILITY_GROUPS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setFacilityGroups = createAction(
    FacilityGroupActions.getActionName(FacilityGroupActions.FACILITY_GROUPS, ActionType.SET),
    props<{ page: Page<FacilityGroupList> }>()
  );

  static setFacilityGroupsActivePage = createAction(
    FacilityGroupActions.getActionName(FacilityGroupActions.FACILITY_GROUP_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static clearFacilityGroups = createAction(
    FacilityGroupActions.getActionName(FacilityGroupActions.FACILITY_GROUPS, ActionType.CLEAR)
  );

  static setActiveFacilityGroup = createAction(
    FacilityGroupActions.getActionName(FacilityGroupActions.ACTIVE_FACILITY_GROUP, ActionType.SET),
    props<{ activeFacilityGroup: FacilityGroup }>()
  );

  static getActiveFacilityGroup = createAction(
    FacilityGroupActions.getActionName(FacilityGroupActions.ACTIVE_FACILITY_GROUP, ActionType.GET),
    props<{ id: string | number }>()
  );

  static clearActiveFacilityGroup = createAction(
    FacilityGroupActions.getActionName(FacilityGroupActions.ACTIVE_FACILITY_GROUP, ActionType.CLEAR)
  );

  static updateFacilityGroup = createAction(
    FacilityGroupActions.getActionName(FacilityGroupActions.FACILITY_GROUP, ActionType.UPDATE),
    props<{ facilityGroup: FacilityGroup }>()
  );

  static createFacilityGroup = createAction(
    FacilityGroupActions.getActionName(FacilityGroupActions.FACILITY_GROUP, ActionType.SAVE),
    props<{ facilityGroup: FacilityGroup }>()
  );

  static facilityGroupSaveSuccessful = createAction(
    FacilityGroupActions.getActionName(FacilityGroupActions.FACILITY_GROUP, ActionType.COMPLETE)
  );

  static facilityGroupSaveUnsuccessful = createAction(
    FacilityGroupActions.getActionName(FacilityGroupActions.FACILITY_GROUP, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static setFacilityGroupFacilitiesActivePage = createAction(
    FacilityGroupActions.getActionName(FacilityGroupActions.FACILITY_GROUP_FACILITIES_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static setFacilityGroupFacilitiesFilter = createAction(
    FacilityGroupActions.getActionName(FacilityGroupActions.FACILITY_GROUP_FACILITIES_FILTER, ActionType.SET),
    props<{ fieldName: string; fieldValue: string }>()
  );
}
