import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  template: '',
  styleUrls: ['./unauthed-base.component.scss']
})
export abstract class UnauthedBaseComponent implements OnInit, OnDestroy {
  protected returnUrl: string = '/';
  protected appliedStyles: { element: HTMLElement; property: string }[] = [];
  isAuthenticating = false;
  errorMessage: string | null = null;

  protected constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected authService: MsalService,
    protected renderer: Renderer2
  ) {
    this.applyAndTrackStyle(
      document.body,
      'background',
      'lightgray url(../../../assets/raistone_darker_gray_bg.png) no-repeat'
    );
    this.applyAndTrackStyle(document.body, 'background-size', 'contain');
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  protected applyAndTrackStyle(element: HTMLElement, property: string, value: string) {
    this.renderer.setStyle(element, property, value);
    this.appliedStyles.push({ element, property });
  }

  protected async preAuthentication(): Promise<void> {
    // Optional hook for child components to implement
  }

  async retryAuthentication() {
    this.isAuthenticating = true;
    this.errorMessage = null;

    try {
      await this.preAuthentication();

      this.authService
        .loginRedirect({
          scopes: ['openid', 'profile'],
          redirectStartPage: this.returnUrl
        })
        .subscribe({
          error: (error) => {
            console.error('Authentication failed:', error);
            this.errorMessage = 'Authentication failed. Please try again.';
            this.isAuthenticating = false;
          }
        });
    } catch (error) {
      console.error('Authentication failed:', error);
      this.errorMessage = 'Authentication failed. Please try again.';
      this.isAuthenticating = false;
    }
  }

  ngOnDestroy() {
    this.appliedStyles.forEach(({ element, property }) => {
      this.renderer.removeStyle(element, property);
    });
  }
}
