import { ActionType, LsAction, PageRequest } from '@limestone/ls-shared-modules';
import { createAction, props } from '@ngrx/store';
import {
  ActivePage,
  ExportRequest,
  CashAppOutbound,
  RemitGridTotals,
  Page,
  CashAppOutboundDetails
} from 'src/app/Models';

export abstract class CashAppOutboundActions extends LsAction {
  /* eslint-disable */
  static CASH_APP_OUTBOUND: Readonly<string> = 'CASH_APP_OUTBOUND';
  static CASH_APP_OUTBOUNDS: Readonly<string> = 'CASH_APP_OUTBOUNDS';
  static CASH_APP_OUTBOUNDS_UPDATE_STATUS: Readonly<string> = 'CASH_APP_OUTBOUNDS_UPDATE_STATUS';
  static CASH_APP_OUTBOUND_EXPORT: Readonly<string> = 'EXPORT/CASH_APP_OUTBOUND';
  static CASH_APP_OUTBOUND_ACTIVE_PAGE: Readonly<string> = 'CASH_APP_OUTBOUND_ACTIVE_PAGE';
  static CASH_APP_OUTBOUND_DETAILS: Readonly<string> = 'CASH_APP_OUTBOUND_DETAILS';
  static CASH_APP_OUTBOUND_UPDATE_STATUS: Readonly<string> = 'CASH_APP_OUTBOUND_UPDATE_STATUS';

    /* eslint-enable */
  static CASH_APP_OUTBOUND_LINE_ITEMS_EXPORT: Readonly<string> = 'CASH_APP_OUTBOUND_LINE_ITEMS_EXPORT';

  static getCashAppOutbounds = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUNDS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setCashAppOutbounds = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUNDS, ActionType.SET),
    props<{ page: Page<CashAppOutbound>; totals: RemitGridTotals }>()
  );

  static cashAppOutboundSuccessful = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUND, ActionType.COMPLETE)
  );

  static cashAppOutboundUnsuccessful = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUND, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static clearCashAppOutbounds = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUND, ActionType.CLEAR)
  );

  static updateCashAppOutboundsStatus = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUNDS_UPDATE_STATUS, ActionType.UPDATE),
    props<{ cashAppOutbounds: CashAppOutbound[]; newStatus: string; pageRequest: PageRequest }>()
  );

  static updateCashAppOutbounds = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUNDS, ActionType.UPDATE),
    props<{ cashAppOutbounds: CashAppOutbound[]; pageRequest: PageRequest }>()
  );

  static triggerCashAppOutboundExport = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUND_EXPORT, ActionType.TRIGGER),
    props<{ exportRequest: ExportRequest }>()
  );

  static cashAppOutboundExportSuccessful = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUND_EXPORT, ActionType.COMPLETE)
  );

  static cashAppOutboundExportUnsuccessful = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUND_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getCashAppOutboundActivePage = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUND_ACTIVE_PAGE, ActionType.GET)
  );

  static setCashAppOutboundActivePage = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUND_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static getCashAppOutboundDetails = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUND_DETAILS, ActionType.GET),
    props<{ id: number }>()
  );

  static setCashAppOutboundDetails = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUND_DETAILS, ActionType.SET),
    props<{ cashAppOutboundDetails: CashAppOutboundDetails }>()
  );

  static updateCashAppOutboundStatus = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUND_UPDATE_STATUS, ActionType.UPDATE),
    props<{ cashAppOutbound: CashAppOutbound; newStatus: string; pageRequest: PageRequest }>()
  );

  static updateCashAppOutbound = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUND_DETAILS, ActionType.UPDATE),
    props<{ cashAppOutbound: CashAppOutbound }>()
  );

  static cashAppOutboundLineItemsExport = createAction(
    CashAppOutboundActions.getActionName(
      CashAppOutboundActions.CASH_APP_OUTBOUND_LINE_ITEMS_EXPORT,
      ActionType.TRIGGER
    ),
    props<{ id: number; exportRequest: ExportRequest }>()
  );

  static cashAppOutboundLineItemsExportSuccessful = createAction(
    CashAppOutboundActions.getActionName(
      CashAppOutboundActions.CASH_APP_OUTBOUND_LINE_ITEMS_EXPORT,
      ActionType.COMPLETE
    )
  );

  static cashAppOutboundLineItemsExportUnsuccessful = createAction(
    CashAppOutboundActions.getActionName(CashAppOutboundActions.CASH_APP_OUTBOUND_LINE_ITEMS_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );
}
