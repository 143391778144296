import { createAction, props } from '@ngrx/store';
import { User, Configuration, LsUserRole } from '../Models';
import { ActionType, LsAction } from './LsAction';

export abstract class AppActions extends LsAction {
  /* eslint-disable */
  static APP: Readonly<string> = 'APP';
  static USER: Readonly<string> = 'USER';
  static USER_ROLES: Readonly<string> = 'USER_ROLES';
  static CONFIG: Readonly<string> = 'CONFIG';
  static SPINNERSTATE: Readonly<string> = 'SPINNERSTATE';
  /* eslint-enable */

  static getActionName = (type: ActionType, key: string) => `${AppActions.APP}\/${type}\/${key}`;

  static setUserRole = createAction(
    AppActions.getActionName(ActionType.SET, AppActions.USER_ROLES),
    props<{ userRole: LsUserRole }>()
  );

  public static setUser = createAction(
    AppActions.getActionName(ActionType.SET, AppActions.USER),
    props<{ user: User }>()
  );

  static getConfig = createAction(AppActions.getActionName(ActionType.GET, AppActions.CONFIG));
  static setConfig = createAction(
    AppActions.getActionName(ActionType.SET, AppActions.CONFIG),
    props<{ config: Configuration }>()
  );

  public static setSpinnerState = createAction(
    AppActions.getActionName(ActionType.SET, AppActions.SPINNERSTATE),
    props<{ isLoading: boolean }>()
  );
}
