import { Page } from './Page';
import { RemitGridTotals } from './RemitGridTotals';
import { InboundRemitGrid } from './InboundRemitGrid';

export class InboundRemitGridData {
  page: Page<InboundRemitGrid>;
  totals: RemitGridTotals;

  constructor(page?: Page<InboundRemitGrid>, totals?: RemitGridTotals) {
    this.page = page ?? null;
    this.totals = totals ?? null;
  }
}
