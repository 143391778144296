import { PaymentMethod } from './PaymentMethod';
import { RemitOutboundDetails } from './RemitOutboundDetails';
import { CashAppRemitLineItem } from './CashAppRemitLineItem';

export class CashAppOutboundDetails extends RemitOutboundDetails<CashAppRemitLineItem> {
  public recipientBankAccountId: number;
  public paymentMethodId: string;
  public paymentMethods: PaymentMethod[];
  public receiptDate: Date;

  constructor(
    remittanceReferenceId?: number,
    remitStatusId?: string,
    compartment?: string,
    compartmentId?: number,
    currencyCode?: string,
    effectiveDate?: Date,
    facilityGroupName?: string,
    facilityGroupId?: number,
    paymentMethodId?: string,
    paymentMethod?: string,
    operationsOwner?: string,
    proposedEffectiveDate?: Date,
    receiptDate?: Date,
    recipientBankAccount?: string,
    recipientBankAccountId?: number,
    recipientId?: number,
    recipient?: string,
    role?: string,
    remittanceAllocatedAmount?: number,
    remittanceNetAmount?: number,
    remittancePurpose?: string,
    remittanceUnallocatedAmount?: number,
    remitTypeId?: string,
    paymentMethods?: PaymentMethod[],
    lineItems?: CashAppRemitLineItem[]
  ) {
    super(
      facilityGroupName,
      facilityGroupId,
      recipient,
      recipientId,
      role,
      proposedEffectiveDate,
      effectiveDate,
      compartment,
      compartmentId,
      remittancePurpose,
      recipientBankAccount,
      paymentMethod,
      operationsOwner,
      remittanceReferenceId,
      remittanceNetAmount,
      currencyCode,
      remittanceAllocatedAmount,
      remittanceUnallocatedAmount,
      remitStatusId,
      remitTypeId,
      lineItems
    );
    this.recipientBankAccountId = recipientBankAccountId;
    this.paymentMethodId = paymentMethodId;
    this.paymentMethods = paymentMethods;
    this.receiptDate = receiptDate;
  }
}
